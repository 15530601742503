import { Mail } from 'lucide-react';
import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { components } from '@/types/strapi';
import { TwoColumnPopup, TwoColumnPopupProps } from './TwoColumnPopup';

export const TwoColumnImageNewsletterSignup = ({
	content,
}: TwoColumnPopupProps) => {
	const [email, setEmail] = useState('');
	const ctaText = content.metadata?.ctaText || 'Subscribe';

	return (
		<TwoColumnPopup content={content} className="newsletter-signup">
			<div className="-mt-2 space-y-5">
				<div className="flex items-center rounded-md bg-blue-50 p-4">
					<Mail className="mr-3 h-5 w-5 flex-shrink-0 text-blue-500" />
					<p className="text-sm text-blue-800">
						Join our community of 30,000+ subscribers
					</p>
				</div>

				<div className="flex flex-col space-y-4">
					<div className="relative">
						<Input
							type="email"
							placeholder="Enter your email"
							value={email}
							onChange={e => setEmail(e.target.value)}
							className="w-full rounded-md border-gray-200 py-6 pl-12 pr-4 focus:border-blue-500"
						/>
						<Mail className="absolute left-4 top-1/2 h-5 w-5 -translate-y-1/2 transform text-gray-400" />
					</div>

					<Button
						className="w-full py-6 text-base"
						variant="primary"
						size="lg">
						{ctaText}
					</Button>
				</div>

				<div className="flex items-center space-x-2 text-xs text-gray-500">
					<svg
						className="h-4 w-4 text-green-500"
						viewBox="0 0 24 24"
						fill="none">
						<path
							d="M20 6L9 17l-5-5"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					<span>Free weekly resources</span>

					<svg
						className="ml-3 h-4 w-4 text-green-500"
						viewBox="0 0 24 24"
						fill="none">
						<path
							d="M20 6L9 17l-5-5"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					<span>Premium content access</span>
				</div>

				<div className="text-center text-xs text-gray-500">
					We respect your privacy. Unsubscribe at any time.
				</div>
			</div>
		</TwoColumnPopup>
	);
};
