'use client';

import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { cn } from '@/lib/utils';

interface MediaCarouselProps {
	images: {
		url?: string;
		alternativeText?: string;
	}[];
	lazyLoad?: boolean;
}

export default function MediaCarousel({
	images,
	lazyLoad = false,
}: MediaCarouselProps) {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isVisible, setIsVisible] = useState(false);
	const carouselRef = useRef<HTMLDivElement>(null);

	// Don't render carousel if only one image
	if (images.length <= 1) {
		return images.length === 1 ? (
			<div
				className="relative h-[280px] w-full overflow-hidden sm:h-[350px] md:h-[400px] lg:h-[500px]"
				ref={carouselRef}>
				{images[0]?.url && (
					<Image
						src={images[0].url}
						alt={images[0].alternativeText || 'Mtalkz Images'}
						fill
						className="object-contain"
						sizes="(max-width: 640px) 100vw, (max-width: 768px) 75vw, (max-width: 1024px) 50vw, 33vw"
						priority={!lazyLoad}
					/>
				)}
			</div>
		) : null;
	}

	// Use Intersection Observer to detect when carousel is visible
	useEffect(() => {
		if (!carouselRef.current) return;

		const observer = new IntersectionObserver(
			entries => {
				if (entries[0].isIntersecting) {
					setIsVisible(true);
					observer.disconnect();
				}
			},
			{ threshold: 0.1 }, // Trigger when 10% of the element is visible
		);

		observer.observe(carouselRef.current);

		return () => {
			observer.disconnect();
		};
	}, []);

	const nextSlide = () => {
		setCurrentIndex(prevIndex =>
			prevIndex === images.length - 1 ? 0 : prevIndex + 1,
		);
	};

	const prevSlide = () => {
		setCurrentIndex(prevIndex =>
			prevIndex === 0 ? images.length - 1 : prevIndex - 1,
		);
	};

	// Auto-advance slides every 5 seconds only when visible
	useEffect(() => {
		if (!isVisible) return;

		const timer = setInterval(nextSlide, 5000);
		return () => clearInterval(timer);
	}, [isVisible]);

	return (
		<div className="group relative w-full" ref={carouselRef}>
			<div className="relative h-[280px] overflow-hidden sm:h-[350px] md:h-[400px] lg:h-[500px]">
				{images.map((image, index) => {
					// Only first image or current image should be rendered immediately
					const shouldRender =
						index === 0 ||
						index === currentIndex ||
						Math.abs(index - currentIndex) === 1;

					return (
						<div
							key={index}
							className={cn(
								'absolute inset-0 transform transition-all duration-700 ease-in-out',
								index === currentIndex
									? 'translate-x-0 opacity-100'
									: index < currentIndex
										? '-translate-x-full opacity-0'
										: 'translate-x-full opacity-0',
							)}>
							<div className="absolute inset-0 bg-gradient-to-tr from-gray-50/20 to-white/0" />
							{image.url && shouldRender && (
								<Image
									src={image.url}
									alt={
										image.alternativeText ||
										'Carousel image'
									}
									fill
									className="object-contain"
									sizes="(max-width: 640px) 100vw, (max-width: 768px) 75vw, (max-width: 1024px) 50vw, 33vw"
									priority={index === 0 && !lazyLoad}
									loading={
										lazyLoad || index > 0 ? 'lazy' : 'eager'
									}
									placeholder="blur"
									blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYPhfDwAChwGA60e6kgAAAABJRU5ErkJggg=="
									fetchPriority={
										index === 0 && !lazyLoad
											? 'high'
											: 'auto'
									}
								/>
							)}
						</div>
					);
				})}
			</div>

			{/* Navigation Buttons */}
			<div className="absolute inset-0 flex items-center justify-between">
				<button
					onClick={prevSlide}
					className={cn(
						'rounded-full bg-white/80 p-1 shadow-lg backdrop-blur-sm sm:p-2',
						'transform transition-all duration-300',
						'opacity-0 hover:bg-white group-hover:opacity-100',
						'hover:scale-110 active:scale-95',
					)}
					aria-label="Previous slide">
					<svg
						className="h-4 w-4 text-gray-800 sm:h-6 sm:w-6"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24">
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M15 19l-7-7 7-7"
						/>
					</svg>
				</button>
				<button
					onClick={nextSlide}
					className={cn(
						'rounded-full bg-white/80 p-1 shadow-lg backdrop-blur-sm sm:p-2',
						'transform transition-all duration-300',
						'opacity-0 hover:bg-white group-hover:opacity-100',
						'hover:scale-110 active:scale-95',
					)}
					aria-label="Next slide">
					<svg
						className="h-4 w-4 text-gray-800 sm:h-6 sm:w-6"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24">
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M9 5l7 7-7 7"
						/>
					</svg>
				</button>
			</div>

			{/* Dots Indicator */}
			<div className="absolute -bottom-8 left-1/2 flex -translate-x-1/2 items-center gap-1 sm:gap-2">
				{images.map((_, index) => (
					<button
						key={index}
						onClick={() => setCurrentIndex(index)}
						className={cn(
							'transform transition-all duration-300',
							'hover:scale-110 active:scale-95',
							index === currentIndex
								? 'h-1.5 w-6 rounded-full bg-blue-600 sm:h-2 sm:w-8'
								: 'h-1.5 w-1.5 rounded-full bg-gray-300 hover:bg-gray-400 sm:h-2 sm:w-2',
						)}
						aria-label={`Go to slide ${index + 1}`}
					/>
				))}
			</div>
		</div>
	);
}
