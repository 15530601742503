import { Check, Star } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { components } from '@/types/strapi';
import { SingleColumnPopup, SingleColumnPopupProps } from './SingleColumnPopup';

export const SingleColumnProductFeature = ({
	content,
}: SingleColumnPopupProps) => {
	const ctaText = content.metadata?.ctaText || 'Learn More';
	const featureHighlight = content.metadata?.featureHighlight || '';

	return (
		<SingleColumnPopup content={content} className="product-feature">
			<div className="-mt-2 px-8 pb-8">
				{featureHighlight && (
					<div className="mb-5 rounded-md">
						<div className="mb-3 flex items-start">
							<div className="mr-3 flex-shrink-0 rounded-full bg-green-100 p-1.5">
								<Check className="h-4 w-4 text-green-600" />
							</div>
							<span className="font-medium text-gray-800">
								{featureHighlight}
							</span>
						</div>

						<div className="mb-5 flex flex-col space-y-3">
							<div className="flex items-center">
								<div className="mr-2 flex">
									{[...Array(5)].map((_, i) => (
										<Star
											key={i}
											className="h-4 w-4 fill-current text-yellow-400"
										/>
									))}
								</div>
								<span className="text-sm text-gray-700">
									4.9/5 (300+ reviews)
								</span>
							</div>

							<div className="flex items-center text-sm text-gray-700">
								<div className="mr-2 h-2 w-2 rounded-full bg-green-500"></div>
								<span>Used by 10,000+ professionals</span>
							</div>
						</div>
					</div>
				)}

				<Button
					className="w-full py-6 text-base"
					variant="primary"
					size="lg">
					{ctaText}
				</Button>

				<div className="mt-4 text-center text-xs text-gray-500">
					30-day money-back guarantee
				</div>
			</div>
		</SingleColumnPopup>
	);
};
