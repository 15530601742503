import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import { components } from '@/types/strapi';
import { PopupContent } from './PopupContent';

interface PopupProps {
	popup: components['schemas']['SharedPopupComponent'];
	buttonText: string;
	buttonVariant?: components['schemas']['SharedButtonComponent']['variant'];
	isOpen: boolean;
	setIsOpen: (open: boolean) => void;
}

export const Popup = ({
	popup,
	buttonText,
	buttonVariant = 'default',
	isOpen,
	setIsOpen,
}: PopupProps) => {
	if (!popup) return null;

	return (
		<Dialog open={isOpen} onOpenChange={setIsOpen}>
			<DialogTrigger asChild>
				<Button size="lg" variant={buttonVariant}>
					{buttonText}
				</Button>
			</DialogTrigger>
			<DialogContent className="m-0 overflow-hidden rounded-lg bg-white p-0 shadow-lg">
				<PopupContent popup={popup} />
			</DialogContent>
		</Dialog>
	);
};
