import { ReactNode } from 'react';
import { FormComponent } from '@/components/FormComponent';
import MediaCarousel from '@/components/media-carousel/MediaCarousel';
import { components } from '@/types/strapi';

export interface TwoColumnPopupProps {
	content: components['schemas']['SharedPopupContentComponent'] & {
		metadata?: {
			discountCode?: string;
			ctaText?: string;
			productPrice?: string;
			downloadLink?: string;
			welcomeMessage?: string;
			newsletterSignup?: boolean;
		};
	};
	className?: string;
	children?: ReactNode;
}

export const TwoColumnPopup = ({
	content,
	className = '',
	children,
}: TwoColumnPopupProps) => {
	const {
		heading,
		description,
		form,
		images,
		imagePosition,
		backgroundColor,
	} = content;
	const isLeft = imagePosition === 'left';
	const isRight = imagePosition === 'right';
	const isTop = imagePosition === 'top';
	const isBackground = imagePosition === 'background';

	// For top image layout
	if (isTop && images && images.length > 0) {
		return (
			<div
				className={`flex w-full flex-col overflow-hidden rounded-lg ${className}`}
				style={{ backgroundColor: backgroundColor || 'white' }}>
				<div className="w-full">
					<MediaCarousel images={images} />
				</div>

				<div className="p-8">
					{heading && (
						<h2 className="mb-3 text-2xl font-bold text-gray-800">
							{heading}
						</h2>
					)}
					{description && (
						<p className="mb-5 text-gray-600">{description}</p>
					)}
					{form && <FormComponent inputs={form?.inputs} />}
					{/* {children} */}
				</div>
			</div>
		);
	}

	// For background image layout
	if (isBackground && images && images.length > 0) {
		return (
			<div
				className={`relative flex min-h-[400px] w-full flex-col overflow-hidden rounded-lg ${className}`}
				style={{ backgroundColor: backgroundColor || 'white' }}>
				<div className="absolute inset-0 z-0">
					<MediaCarousel images={images} />
				</div>

				<div className="relative z-10 m-6 rounded-lg bg-white/90 p-8 shadow-md backdrop-blur-sm">
					{heading && (
						<h2 className="mb-3 text-2xl font-bold text-gray-800">
							{heading}
						</h2>
					)}
					{description && (
						<p className="mb-5 text-gray-600">{description}</p>
					)}
					{form && <FormComponent inputs={form?.inputs} />}
					{/* {children} */}
				</div>
			</div>
		);
	}

	// For left/right image layout (default)
	return (
		<div
			className={`flex w-full flex-col items-stretch overflow-hidden rounded-lg md:flex-row ${className}`}
			style={{ backgroundColor: backgroundColor || 'white' }}>
			{isLeft && images && images.length > 0 && (
				<div className="flex-1 md:w-1/2">
					<MediaCarousel images={images} />
				</div>
			)}

			<div className="flex-1 p-8 md:w-1/2">
				{heading && (
					<h2 className="mb-3 text-2xl font-bold text-gray-800">
						{heading}
					</h2>
				)}
				{description && (
					<p className="mb-5 text-gray-600">{description}</p>
				)}
				{form && <FormComponent inputs={form?.inputs} />}
				{/* {children} */}
			</div>

			{isRight && images && images.length > 0 && (
				<div className="flex-1 md:w-1/2">
					<MediaCarousel images={images} />
				</div>
			)}
		</div>
	);
};
