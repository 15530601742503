import { Button } from '@/components/ui/button';
import { components } from '@/types/strapi';
import { TwoColumnPopup, TwoColumnPopupProps } from './TwoColumnPopup';

export const TwoColumnImageWelcomeMessage = ({
	content,
}: TwoColumnPopupProps) => {
	const ctaText = content.metadata?.ctaText || 'Get Started';
	const welcomeMessage = content.metadata?.welcomeMessage || '';

	return (
		<TwoColumnPopup content={content} className="welcome-message">
			{welcomeMessage && (
				<div className="-mt-1 mb-6 rounded-md border-l-4 border-blue-400 bg-blue-50 p-4">
					<p className="text-sm italic text-blue-800">
						{welcomeMessage}
					</p>
				</div>
			)}

			<div className="space-y-4">
				<Button
					className="w-full py-6 text-base"
					variant="primary"
					size="lg">
					{ctaText}
				</Button>

				<div className="flex items-center justify-center">
					<span className="mr-4 h-px flex-1 bg-gray-200"></span>
					<span className="text-xs text-gray-500">OR</span>
					<span className="ml-4 h-px flex-1 bg-gray-200"></span>
				</div>

				<Button
					className="w-full py-6 text-base"
					variant="outline"
					size="lg">
					View Demo
				</Button>
			</div>
		</TwoColumnPopup>
	);
};
