import { Clock, Copy } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { components } from '@/types/strapi';
import { TwoColumnPopup, TwoColumnPopupProps } from './TwoColumnPopup';

export const TwoColumnImageDiscountOffer = ({
	content,
}: TwoColumnPopupProps) => {
	const discountCode = content.metadata?.discountCode || '';
	const ctaText = content.metadata?.ctaText || 'Get Discount';

	const handleCopyCode = () => {
		if (discountCode) {
			navigator.clipboard.writeText(discountCode);
		}
	};

	return (
		<TwoColumnPopup content={content} className="discount-offer">
			<div className="-mt-2 space-y-5">
				<div className="rounded-md border border-gray-100 bg-gray-50 p-4">
					<div className="mb-2 flex items-center justify-between">
						<span className="text-sm font-medium text-gray-700">
							Limited time offer
						</span>
						<div className="flex items-center text-sm text-orange-600">
							<Clock className="mr-1 h-4 w-4" />
							<span>Expires in 24h</span>
						</div>
					</div>

					{discountCode && (
						<div className="flex items-center space-x-3">
							<div className="relative flex-grow rounded-md border border-dashed border-gray-300 bg-white p-3 text-center">
								<span className="font-mono text-lg font-bold tracking-wider">
									{discountCode}
								</span>
								<button
									onClick={handleCopyCode}
									className="absolute right-2 top-1/2 -translate-y-1/2 transform text-gray-400 hover:text-gray-600"
									title="Copy code">
									<Copy className="h-4 w-4" />
								</button>
							</div>
						</div>
					)}
				</div>

				<Button
					className="w-full py-6 text-base"
					variant="primary"
					size="lg">
					{ctaText}
				</Button>

				<div className="flex justify-center">
					<button className="text-sm text-gray-500 hover:text-gray-700">
						No thanks, continue to site
					</button>
				</div>

				<div className="mt-3 flex items-center justify-center text-xs text-gray-500">
					<svg
						className="mr-2 h-4 w-4 text-green-500"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor">
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
						/>
					</svg>
					100% secure checkout | Money-back guarantee
				</div>
			</div>
		</TwoColumnPopup>
	);
};
