import { Clock } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { components } from '@/types/strapi';
import { SingleColumnPopup, SingleColumnPopupProps } from './SingleColumnPopup';

export const SingleColumnExclusiveSale = ({
	content,
}: SingleColumnPopupProps) => {
	const ctaText = content.metadata?.ctaText || 'Shop Now';
	const discountCode = content.metadata?.discountCode || '';

	return (
		<SingleColumnPopup content={content} className="exclusive-sale">
			<div className="-mt-2 px-8 pb-8">
				<div className="mb-5 rounded-md bg-red-50 p-4">
					<div className="flex items-center justify-center">
						<Clock className="mr-2 h-5 w-5 text-red-600" />
						<span className="text-lg font-semibold text-red-600">
							Limited Time Offer!
						</span>
					</div>
					<div className="mt-3 flex justify-center">
						<div className="grid grid-cols-4 gap-2 text-center">
							<div className="rounded-md bg-white p-2">
								<span className="block text-lg font-bold">
									00
								</span>
								<span className="text-xs text-gray-500">
									Days
								</span>
							</div>
							<div className="rounded-md bg-white p-2">
								<span className="block text-lg font-bold">
									12
								</span>
								<span className="text-xs text-gray-500">
									Hours
								</span>
							</div>
							<div className="rounded-md bg-white p-2">
								<span className="block text-lg font-bold">
									45
								</span>
								<span className="text-xs text-gray-500">
									Minutes
								</span>
							</div>
							<div className="rounded-md bg-white p-2">
								<span className="block text-lg font-bold">
									30
								</span>
								<span className="text-xs text-gray-500">
									Seconds
								</span>
							</div>
						</div>
					</div>
				</div>

				{discountCode && (
					<div className="mb-5 w-full rounded-md border border-dashed border-gray-300 bg-gray-100 p-4 text-center">
						<div className="mb-1 text-xs uppercase text-gray-500">
							Use code
						</div>
						<span className="font-mono text-xl font-bold tracking-wider">
							{discountCode}
						</span>
					</div>
				)}

				<Button
					className="w-full py-6 text-base"
					variant="primary"
					size="lg">
					{ctaText}
				</Button>
			</div>
		</SingleColumnPopup>
	);
};
