'use client';

import { ArrowRight, Phone } from 'lucide-react';
import { useState } from 'react';
import { Popup } from '@/components/popup';
import { Button } from '@/components/ui/button';
import { components } from '@/types/strapi';

interface CTAProps {
	CTAs: components['schemas']['SharedButtonComponent'][];
}

export const CTA = ({ CTAs }: CTAProps) => {
	return (
		<div className="flex flex-wrap gap-4">
			{CTAs?.map(cta => {
				const [isOpen, setIsOpen] = useState(false);
				const isPhone = /^[\d\s+-]+$/.test(cta.text || '');

				return cta.URL ? (
					<Button
						key={cta.id}
						size="lg"
						variant={cta.variant}
						asChild>
						<a href={cta.URL} target={cta.target}>
							<span className="flex items-center gap-2">
								{isPhone && <Phone className="h-4 w-4" />}
								{cta.text}
								{!isPhone && cta.variant === 'primary' && (
									<ArrowRight className="h-4 w-4" />
								)}
							</span>
						</a>
					</Button>
				) : (
					<div key={cta.id}>
						{cta.popup && (
							<Popup
								popup={cta.popup}
								buttonText={cta.text || ''}
								buttonVariant={cta.variant}
								isOpen={isOpen}
								setIsOpen={setIsOpen}
							/>
						)}
					</div>
				);
			})}
		</div>
	);
};
