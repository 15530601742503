import { Button } from '@/components/ui/button';
import { components } from '@/types/strapi';
import { SingleColumnPopup, SingleColumnPopupProps } from './SingleColumnPopup';

export const SingleColumnDiscountOffer = ({
	content,
}: SingleColumnPopupProps) => {
	const { heading, description } = content;
	const discountCode = content.metadata?.discountCode || '';
	const ctaText = content.metadata?.ctaText || 'Get Discount';

	return (
		<SingleColumnPopup content={content} className="discount-offer">
			<div className="-mt-2 px-8 pb-8">
				{discountCode && (
					<div className="mb-5 flex flex-col items-center">
						<div className="w-full rounded-md border border-gray-200 bg-gray-100 p-4 text-center">
							<span className="font-mono text-xl font-bold tracking-wider">
								{discountCode}
							</span>
						</div>
						<span className="mt-2 text-xs text-gray-500">
							Use this code at checkout
						</span>
					</div>
				)}

				<Button
					className="w-full py-6 text-base"
					variant="primary"
					size="lg">
					{ctaText}
				</Button>

				<div className="mt-4 flex justify-center">
					<button className="text-sm text-gray-500 hover:text-gray-700">
						No thanks, I'll pay full price
					</button>
				</div>
			</div>
		</SingleColumnPopup>
	);
};
