import { ShoppingCart, Star } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { components } from '@/types/strapi';
import { SingleColumnPopup, SingleColumnPopupProps } from './SingleColumnPopup';

export const SingleColumnProductPromotion = ({
	content,
}: SingleColumnPopupProps) => {
	const ctaText = content.metadata?.ctaText || 'Shop Now';
	const productPrice = content.metadata?.productPrice || '';

	return (
		<SingleColumnPopup content={content} className="product-promotion">
			<div className="-mt-2 px-8 pb-8">
				{productPrice && (
					<div className="mb-5 flex flex-col items-center">
						<div className="mb-3 flex w-full items-center justify-between">
							<div className="flex items-center">
								<div className="mr-2 flex">
									{[...Array(5)].map((_, i) => (
										<Star
											key={i}
											className="h-4 w-4 fill-current text-yellow-400"
										/>
									))}
								</div>
								<span className="text-sm text-gray-700">
									(25)
								</span>
							</div>
							<span className="rounded bg-green-100 px-2 py-1 text-sm text-green-800">
								In Stock
							</span>
						</div>

						<div className="mb-2 w-full rounded-md border border-gray-200 bg-gray-50 p-4">
							<div className="flex items-center justify-between">
								<div>
									<span className="mr-2 text-sm text-gray-500 line-through">
										$129.99
									</span>
									<span className="text-2xl font-bold text-gray-900">
										{productPrice}
									</span>
								</div>
								<span className="rounded bg-red-100 px-2 py-1 text-sm text-red-800">
									Save 25%
								</span>
							</div>
						</div>
					</div>
				)}

				<Button
					className="flex w-full items-center justify-center py-6 text-base"
					variant="primary"
					size="lg">
					<ShoppingCart className="mr-2 h-5 w-5" />
					{ctaText}
				</Button>

				<div className="mt-4 flex items-center justify-center text-xs text-gray-500">
					<svg
						viewBox="0 0 24 24"
						className="mr-1 h-4 w-4"
						fill="none"
						stroke="currentColor"
						strokeWidth="2">
						<path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
					</svg>
					Secure checkout | Free shipping on orders over $50
				</div>
			</div>
		</SingleColumnPopup>
	);
};
