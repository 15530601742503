import { ContactForm } from '@/components/ContactForm';
import { components } from '@/types/strapi';
import { InternalPopupContent } from './InternalPopupContent';

interface PopupContentProps {
	popup: components['schemas']['SharedPopupComponent'];
}

export const PopupContent = ({ popup }: PopupContentProps) => {
	if (!popup) return null;

	switch (popup.popupType) {
		case 'internal':
			if (!popup.internalContent) return null;
			return <InternalPopupContent content={popup.internalContent} />;

		case 'external':
			if (!popup.externalScript) return null;
			return <ContactForm script={popup.externalScript} />;

		default:
			return null;
	}
};
