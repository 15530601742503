import { Download } from 'lucide-react';
import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { components } from '@/types/strapi';
import { TwoColumnPopup, TwoColumnPopupProps } from './TwoColumnPopup';

export const TwoColumnImageDownloadForm = ({
	content,
}: TwoColumnPopupProps) => {
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const ctaText = content.metadata?.ctaText || 'Download Now';
	const downloadLink = content.metadata?.downloadLink || '#';

	return (
		<TwoColumnPopup content={content} className="download-form">
			<div className="-mt-2 space-y-5">
				<div className="mb-2 rounded-md bg-blue-50 p-4">
					<p className="text-sm text-blue-700">
						Fill out the form to get immediate access to your
						download
					</p>
				</div>

				<div className="space-y-3">
					<Input
						placeholder="Your name"
						value={name}
						onChange={e => setName(e.target.value)}
						className="w-full border-gray-200 px-4 py-5"
					/>

					<Input
						type="email"
						placeholder="Your email"
						value={email}
						onChange={e => setEmail(e.target.value)}
						className="w-full border-gray-200 px-4 py-5"
					/>
				</div>

				<Button
					className="w-full py-6 text-base"
					variant="primary"
					size="lg"
					asChild>
					<a href={downloadLink} download>
						<Download className="mr-2 h-5 w-5" />
						{ctaText}
					</a>
				</Button>

				<div className="flex items-center justify-center text-xs text-gray-500">
					<svg
						className="mr-2 h-4 w-4 text-green-500"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor">
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
						/>
					</svg>
					We won't send you spam. Unsubscribe at any time.
				</div>
			</div>
		</TwoColumnPopup>
	);
};
