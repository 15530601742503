'use client';

import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { components } from '@/types/strapi';

export const FormComponent: React.FC<
	components['schemas']['SharedFormComponent']
> = ({ inputs }) => {
	const [formData, setFormData] = useState<{ [key: string]: string }>({});

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		fallbackName: string,
	) => {
		const { name, value } = e.target;
		const key = name || fallbackName;
		setFormData(prev => ({ ...prev, [key]: value }));
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		// Prepare payload with metadata
		const payload = {
			submittedValues: {
				fields: inputs
					?.filter(input => input.type !== 'submit') // Exclude submit button
					.map((input, index) => ({
						name: input.name || `unnamed_${index}`,
						value: formData[input.name || `unnamed_${index}`] || '',
					})),
			},
			pageURL: window.location.href, // Capture current page URL
			submittedBy: 'Anonymous', // Could be dynamic if user auth is added
			submittedAt: new Date().toISOString(),
			userAgent: navigator.userAgent, // Browser info
		};

		try {
			const response = await fetch(
				process.env.NEXT_PUBLIC_API_URL + '/api/form-submissions',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						// 'Authorization': 'Bearer YOUR_API_TOKEN', // Uncomment if needed
					},
					body: JSON.stringify({ data: payload }),
				},
			);

			if (!response.ok) throw new Error('Failed to submit form');
			const result = await response.json();
			alert('Form submitted successfully!');
			setFormData({});
		} catch (error) {
			console.error('Error:', error);
			alert('Error submitting form.');
		}
	};

	return (
		<form className="space-y-6" onSubmit={handleSubmit}>
			{inputs?.map((input, index) => {
				const inputName = input.name || `unnamed_${index}`;
				return (
					<div key={inputName} className="space-y-2">
						{input.type !== 'submit' && (
							<label
								htmlFor={inputName}
								className="block text-sm font-medium leading-6">
								{input.name || 'Unnamed Field'}
							</label>
						)}
						<div className="mt-1">
							{input.type === 'textarea' ? (
								<textarea
									rows={5}
									id={inputName}
									name={inputName}
									placeholder={input.placeholder}
									value={formData[inputName] || ''}
									onChange={e => handleChange(e, inputName)}
									className="border-border-color placeholder:text-placeholder block w-full rounded-md border bg-background px-4 py-2 text-primary focus:outline-none focus:ring-2 focus:ring-accent sm:text-sm sm:leading-6"
									aria-label={input.name || 'Unnamed Field'}
								/>
							) : input.type === 'submit' ? (
								<Button
									type="submit"
									className="hover).'bg-primary-dark mt-6 w-full rounded-md bg-primary py-2 font-semibold text-white transition duration-300">
									{input.name || 'Submit'}
								</Button>
							) : (
								<input
									id={inputName}
									name={inputName}
									type={input.type}
									placeholder={input.placeholder}
									value={formData[inputName] || ''}
									onChange={e => handleChange(e, inputName)}
									className="border-border-color placeholder:text-placeholder block w-full rounded-md border bg-background px-4 py-2 text-primary focus:outline-none focus:ring-2 focus:ring-accent sm:text-sm sm:leading-6"
									aria-label={input.name || 'Unnamed Field'}
								/>
							)}
						</div>
					</div>
				);
			})}
		</form>
	);
};
