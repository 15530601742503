import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { components } from '@/types/strapi';
import { SingleColumnPopup, SingleColumnPopupProps } from './SingleColumnPopup';

export const SingleColumnNewsletterSignup = ({
	content,
}: SingleColumnPopupProps) => {
	const [email, setEmail] = useState('');
	const ctaText = content.metadata?.ctaText || 'Subscribe';

	return (
		<SingleColumnPopup content={content} className="newsletter-signup">
			<div className="-mt-2 px-8 pb-8">
				<div className="flex flex-col space-y-4">
					<div className="mb-1 rounded-md bg-blue-50 p-3 text-center">
						<span className="text-sm text-blue-700">
							Join over 30,000+ subscribers
						</span>
					</div>

					<Input
						type="email"
						placeholder="Enter your email"
						value={email}
						onChange={e => setEmail(e.target.value)}
						className="w-full border-gray-200 px-4 py-6 focus:border-blue-500"
					/>

					<Button
						className="w-full py-6 text-base"
						variant="primary"
						size="lg">
						{ctaText}
					</Button>

					<div className="mt-2 flex items-center justify-center text-center text-xs text-gray-500">
						<svg
							className="mr-2 h-4 w-4 text-gray-400"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round">
							<path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
						</svg>
						We respect your privacy. Unsubscribe at any time.
					</div>
				</div>
			</div>
		</SingleColumnPopup>
	);
};
