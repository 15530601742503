import { ReactNode } from 'react';
import { FormComponent } from '@/components/FormComponent';
import MediaCarousel from '@/components/media-carousel/MediaCarousel';
import { components } from '@/types/strapi';

export interface SingleColumnPopupProps {
	content: components['schemas']['SharedPopupContentComponent'] & {
		metadata?: {
			discountCode?: string;
			ctaText?: string;
			productPrice?: string;
			ageVerification?: boolean;
			newsletterSignup?: boolean;
			exclusiveSale?: boolean;
			featureHighlight?: string;
			downloadLink?: string;
			welcomeMessage?: string;
		};
	};
	className?: string;
	children?: ReactNode;
}

export const SingleColumnPopup = ({
	content,
	className = '',
	children,
}: SingleColumnPopupProps) => {
	const { heading, description, form, images, backgroundColor } = content;

	return (
		<div
			className={`flex w-full flex-col overflow-hidden rounded-lg shadow-sm ${className}`}
			style={{ backgroundColor: backgroundColor || 'white' }}>
			{images && images.length > 0 && (
				<div className="w-full">
					<MediaCarousel images={images} />
				</div>
			)}
			<div className="p-8">
				{heading && (
					<h2 className="mb-3 text-2xl font-bold text-gray-800">
						{heading}
					</h2>
				)}
				{description && (
					<p className="mb-5 text-gray-600">{description}</p>
				)}
				{form && <FormComponent inputs={form?.inputs} />}
			</div>
			{/* {children} */}
		</div>
	);
};
