import { AlertTriangle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { components } from '@/types/strapi';
import { SingleColumnPopup, SingleColumnPopupProps } from './SingleColumnPopup';

export const SingleColumnAgeVerification = ({
	content,
}: SingleColumnPopupProps) => {
	const yesText = content.metadata?.ctaText || 'Yes, I am old enough';

	return (
		<SingleColumnPopup content={content} className="age-verification">
			<div className="-mt-2 px-8 pb-8">
				<div className="mb-6 flex items-start rounded-md bg-orange-50 p-4">
					<AlertTriangle className="mr-3 mt-0.5 h-5 w-5 flex-shrink-0 text-orange-500" />
					<p className="text-sm text-orange-800">
						This content may not be suitable for all ages. Please
						confirm you meet the minimum age requirement.
					</p>
				</div>

				<div className="flex flex-col justify-center gap-4 md:flex-row">
					<Button
						className="flex-1 py-6 text-base"
						variant="primary"
						size="lg">
						{yesText}
					</Button>

					<Button
						className="flex-1 py-6 text-base"
						variant="outline"
						size="lg">
						No, I am not
					</Button>
				</div>

				<div className="mt-4 text-center text-xs text-gray-500">
					By clicking "Yes", you confirm that you are of legal age in
					your region.
				</div>
			</div>
		</SingleColumnPopup>
	);
};
