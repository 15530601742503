import { components } from '@/types/strapi';
import {
	SingleColumnAgeVerification,
	SingleColumnDiscountOffer,
	SingleColumnExclusiveSale,
	SingleColumnNewsletterSignup,
	SingleColumnPopup,
	SingleColumnProductFeature,
	SingleColumnProductPromotion,
	TwoColumnImageDiscountOffer,
	TwoColumnImageDownloadForm,
	TwoColumnImageNewsletterSignup,
	TwoColumnImageWelcomeMessage,
	TwoColumnPopup,
} from './layouts';

interface InternalPopupContentProps {
	content: components['schemas']['SharedPopupContentComponent'];
}

export const InternalPopupContent = ({
	content,
}: InternalPopupContentProps) => {
	const { layout } = content;

	// Single column layouts
	if (layout === 'single-column-discount-offer') {
		return <SingleColumnDiscountOffer content={content} />;
	}

	if (layout === 'single-column-product-promotion') {
		return <SingleColumnProductPromotion content={content} />;
	}

	if (layout === 'single-column-newsletter-signup') {
		return <SingleColumnNewsletterSignup content={content} />;
	}

	if (layout === 'single-column-exclusive-sale') {
		return <SingleColumnExclusiveSale content={content} />;
	}

	if (layout === 'single-column-age-verification') {
		return <SingleColumnAgeVerification content={content} />;
	}

	if (layout === 'single-column-product-feature') {
		return <SingleColumnProductFeature content={content} />;
	}

	// Two column layouts
	if (layout === 'two-column-image-discount-offer') {
		return <TwoColumnImageDiscountOffer content={content} />;
	}

	if (layout === 'two-column-image-newsletter-signup') {
		return <TwoColumnImageNewsletterSignup content={content} />;
	}

	if (layout === 'two-column-image-download-form') {
		return <TwoColumnImageDownloadForm content={content} />;
	}

	if (layout === 'two-column-image-welcome-message') {
		return <TwoColumnImageWelcomeMessage content={content} />;
	}

	// Fallback to basic layouts
	if (layout === 'two-column') {
		return <TwoColumnPopup content={content} />;
	}

	// Default to single column
	return <SingleColumnPopup content={content} />;
};
